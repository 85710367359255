<template>
  <CSidebar
    class="iemq-sidebar"
    fixed
    :minimize="minimize"
    :show="show"
    @update:show="updateSidebar"
  >
    <CSidebarBrand class="header" to="/">
      <img class="image-logo" src="img/logo.png" />
    </CSidebarBrand>

    <CRenderFunction flat :content-to-render="$options.nav" />
  </CSidebar>
</template>

<script>
import { mapActions } from "vuex";
import nav from "./_nav";

export default {
  name: "TheSidebar",

  data() {
    return {
      appName: process.env.VUE_APP_NAME || "MEGAQUIZ",
      appAcronym: process.env.VUE_APP_ACRONYM || "MQ",
      appVersion: process.env.VUE_APP_VERSION || "0.0.1",
    };
  },

  nav,

  computed: {
    show() {
      return this.$store.state.app.sidebarShow;
    },

    minimize() {
      return this.$store.state.app.sidebarMinimize;
    },
  },

  methods: {
    ...mapActions({
      // setSidebarMinimize: "app/setSidebarMinimize",
      toggleSidebarMobile: "app/toggleSidebarMobile",
    }),

    updateSidebar(value) {
      if (value === "responsive") {
        // this.setSidebarMinimize(value);
        this.toggleSidebarMobile();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.iemq-sidebar {
  background-color: #fff;
  box-shadow: 0px 4px 16px 1px rgba(51, 51, 51, 0.1);

  .image-logo {
    width: 63px;
    height: 53px;
  }

  &.c-sidebar-show {
    .header {
      width: 100%;
    }
  }

  .header {
    margin: 0;
    box-sizing: content-box;
    align-items: center;
    justify-content: flex-start;
    padding: 10px 25px;
    box-sizing: border-box;
    background: #fff;
    box-shadow: rgba(33, 35, 38, 0.1) 0px 10px 10px -10px;
    width: calc(100% + 20px);
    pointer-events: none;

    img {
      pointer-events: all;
    }
  }

  ::v-deep {
    ul > li {
      & > a {
        color: #333333;
        transition: border 0.2s;
        border-left: 0px solid #00338d;

        &::after {
          filter: invert(1);
          background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 11 14'%3E%3Cpath fill='%23fff' d='M9.148 2.352l-4.148 4.148 4.148 4.148q0.148 0.148 0.148 0.352t-0.148 0.352l-1.297 1.297q-0.148 0.148-0.352 0.148t-0.352-0.148l-5.797-5.797q-0.148-0.148-0.148-0.352t0.148-0.352l5.797-5.797q0.148-0.148 0.352-0.148t0.352 0.148l1.297 1.297q0.148 0.148 0.148 0.352t-0.148 0.352z'/%3E%3C/svg%3E");
        }

        rect,
        path {
          fill: #333333;
          transition: fill 0.2s;
        }

        &:hover {
          background-color: #e4eaf3;
          color: #333333;
          border-left: 8px solid #00338d;

          rect,
          path {
            fill: #333333;
          }
        }

        &.c-active {
          background-color: #e4eaf3;
          color: #333333;
          border-left: 8px solid #00338d;
        }
      }

      &.c-sidebar-nav-dropdown.c-show {
        background-color: #e4eaf3;

        &::after {
          filter: invert(1);
        }

        & > a {
          color: #333333;

          &:hover {
            background-color: #e4eaf3;
            color: #333333;
          }
        }

        li {
          background-color: #e4eaf3;

          & > a {
            color: #333333;

            &:hover {
              background-color: #e4eaf3;
              color: #333333;
            }
          }
        }
      }
    }
  }
}
</style>
