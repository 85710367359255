export default [
  {
    _name: "CSidebarNav",
    _children: [
      {
        _name: "CSidebarNavDropdown",
        name: "Quizzes",
        route: "/quizzes",
        icon: "cil-list",
        items: [
          {
            name: "All",
            to: "/quizzes",
          },
          {
            name: "Add new",
            to: "/quizzes/add",
          },
        ],
      },
      {
        _name: "CSidebarNavItem",
        name: "Users",
        to: "/users",
        icon: "cil-people",
      },
    ],
  },
];
