<template>
  <CFooter :fixed="false" class="d-none">
    <!-- <div>
      <a href="https://ie.edu" target="_blank">IE University</a>
      <span class="ml-1">&copy; {{ new Date().getFullYear() }} IE.EDU</span>
    </div> -->
    <!-- <div class="mfs-auto">
      <span class="mr-1" target="_blank">Powered by</span>
      <a href="https://ie.edu">CoreUI for Vue</a>
    </div> -->
  </CFooter>
</template>

<script>
export default {
  name: "TheFooter",
};
</script>
