<template>
  <CButton
    @click="handleQuizSubmit"
    type="submit"
    color="primary"
    class="iemq"
    :disabled="loading"
  >
    <slot></slot>
  </CButton>
</template>

<script>
import { mapActions, mapState } from "vuex";
import axios from "axios";
import qs from "qs";
import { createHelpers } from "vuex-map-fields";
import { uuid } from "vue-uuid";

const { mapFields } = createHelpers({
  getterType: "quiz/getField",
  mutationType: "quiz/updateField",
});

const apiUrl = process.env.VUE_APP_API_URL || "http://localhost:1337";

export default {
  name: "CQuizSaveButton",

  data() {
    return {};
  },

  computed: {
    ...mapState({
      user: (state) => state.auth.user,
      jwtToken: (state) => state.auth.jwt,
      loading: (state) => state.app.loading,
      crud: (state) => state.quiz.crud,
    }),

    ...mapFields([
      "currentQuiz",
      "currentQuiz.questions",
      "currentQuiz.results",
      "currentQuestion",
      "currentResult",
      "currentContent",
    ]),
  },

  methods: {
    ...mapActions({
      startLoading: "app/startLoading",
      stopLoading: "app/stopLoading",
    }),

    async handleQuizSubmit() {
      try {
        await this.startLoading();
        await this.uploadImages();
        this.currentQuiz = await this.upsertQuizFull();

        this.$toasted.success("Quiz successfully saved", {
          icon: "check_circle",
        });

        if (this.crud === "add") {
          this.$router.push({ path: "/quizzes" });
        }
      } catch (error) {
        console.log({ error });
        this.$toasted.error("Something went wrong", { icon: "error" });
      } finally {
        await this.stopLoading();
      }
    },

    async uploadImages() {
      const uploadBaseUrl = `${apiUrl}/upload/`;
      const quizHasImage =
        this.currentQuiz.image && this.currentQuiz.image.length > 0;
      const results = [...this.results];
      const questions = [...this.questions];

      if (quizHasImage) {
        const image = this.currentQuiz.image[0];

        if (image.isNew) {
          const formData = new FormData();
          formData.append("files", image.files[0]);

          const createdImage = await axios.post(uploadBaseUrl, formData, {
            headers: {
              Authorization: `Bearer ${this.jwtToken}`,
            },
          });

          this.currentQuiz.image = [createdImage.data[0]];
        }
      } else {
        this.currentQuiz.image = null;
      }

      for (const result of results) {
        const resultHasImage = result.image && result.image.length > 0;

        if (resultHasImage) {
          const image = result.image[0];

          if (image.isNew) {
            const formData = new FormData();
            formData.append("files", image.files[0]);

            const createdImage = await axios.post(uploadBaseUrl, formData, {
              headers: {
                Authorization: `Bearer ${this.jwtToken}`,
              },
            });

            result.image = [createdImage.data[0]];
          }
        } else {
          result.image = null;
        }
      }

      for (const question of questions) {
        const questionHasImage = question.image && question.image.length > 0;

        if (questionHasImage) {
          const image = question.image[0];

          if (image.isNew) {
            const formData = new FormData();
            formData.append("files", image.files[0]);

            const createdImage = await axios.post(uploadBaseUrl, formData, {
              headers: {
                Authorization: `Bearer ${this.jwtToken}`,
              },
            });

            question.image = [createdImage.data[0]];
          }
        } else {
          question.image = null;
        }
      }

      this.results = results;
      this.questions = questions;
    },

    async upsertQuizFull() {
      const quiz = { ...this.currentQuiz };
      const baseUrl = `${apiUrl}/quizzes/upsert`;

      delete quiz.games;

      const upsertedQuiz = await axios.post(baseUrl, quiz, {
        headers: {
          Authorization: `Bearer ${this.jwtToken}`,
        },
      });

      return upsertedQuiz.data.quiz;
    },
  },
};
</script>

<style lang="scss" scoped></style>
