<template>
  <CDropdown inNav class="c-header-nav-items" placement="bottom-start">
    <template #toggler>
      <div class="toggler">
        <CHeaderNavLink>
          <div class="c-avatar mr-2">
            <img src="img/avatars/default.jpg" class="c-avatar-img" />
          </div>
          <strong>{{ user.username }}</strong>
        </CHeaderNavLink>
      </div>
    </template>

    <CDropdownItem to="/profile"
      ><CIcon name="cil-user" /> Profile</CDropdownItem
    >
    <CDropdownItem @click="endSession()">
      <CIcon name="cil-lock-locked" /> Logout
    </CDropdownItem>
  </CDropdown>
</template>

<script>
import { mapActions, mapState } from "vuex";

export default {
  name: "TheHeaderDropdownAccnt",

  data() {
    return {
      itemsCount: 42,
    };
  },

  computed: {
    ...mapState({
      user: (state) => state.auth.user,
      loading: (state) => state.app.loading,
    }),
  },

  methods: {
    ...mapActions({
      logout: "auth/logout",
    }),

    async endSession() {
      await this.logout();
      this.$router.push({ name: "Login" });
    },
  },
};
</script>

<style scoped lang="scss">
.c-icon {
  margin-right: 0.3rem;
}

.c-header-nav-items {
  .toggler {
    padding-right: 10px;

    strong {
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 17px;
      color: #333333;
    }

    strong::after {
      display: inline-block;
      vertical-align: 0.255em;
      content: "";
      border-top: 0.3em solid;
      border-right: 0.3em solid transparent;
      border-bottom: 0;
      border-left: 0.3em solid transparent;
      margin-left: 12px;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
    }
  }
}
</style>
