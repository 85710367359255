<template>
  <CHeader fixed with-subheader light class="iemq-header">
    <div class="shadowed">
      <CToggler
        in-header
        class="ml-0 d-lg-none"
        @click="toggleSidebarMobile()"
      />
      <CToggler
        in-header
        class="ml-0 d-md-down-none"
        @click="toggleSidebarDesktop()"
      />

      <CHeaderBrand class="mr-auto" to="/" v-if="!sidebarShown">
        <img class="image-logo" src="img/logo.png" />
      </CHeaderBrand>
      <CHeaderBrand class="mr-auto d-lg-none" to="/" v-else>
        <img class="image-logo" src="img/logo.png" />
      </CHeaderBrand>

      <CHeaderNav class="d-md-down-none mr-auto"> </CHeaderNav>

      <div
        v-if="isQuizPage"
        class="d-lg-down-none quiz-navigation d-flex align-items-center justify-content-between w-100"
      >
        <router-link
          to="/quizzes"
          class="return-link d-flex align-items-center justify-content-start"
        >
          <CIcon name="cil-arrow-left" class="mr-2" />
          Go to quizzes
        </router-link>

        <div class="d-flex align-items-center justify-content-start quiz-links">
          <span
            @click="quizNavigate('settings')"
            :class="{ active: quizNavigation === 'settings' }"
          >
            Settings
          </span>
          <span
            @click="quizNavigate('content')"
            :class="{ active: quizNavigation === 'content' }"
          >
            Content
          </span>
          <span
            @click="quizNavigate('design')"
            :class="{ active: quizNavigation === 'design' }"
          >
            Design
          </span>
        </div>

        <QuizSaveButton class="mr-3 quiz-button">Save changes</QuizSaveButton>
      </div>

      <CHeaderNav class="mr-4">
        <TheHeaderDropdownAccnt />
      </CHeaderNav>
    </div>
  </CHeader>
</template>

<script>
import TheHeaderDropdownAccnt from "./TheHeaderDropdownAccnt";
import { mapActions, mapState } from "vuex";
import QuizSaveButton from "../components/QuizSaveButton.vue";

export default {
  name: "TheHeader",

  data() {
    return {
      isQuizPage: false,
    };
  },

  components: {
    TheHeaderDropdownAccnt,
    QuizSaveButton,
  },

  computed: {
    ...mapState({
      jwtToken: (state) => state.auth.jwt,
      quizNavigation: (state) => state.quiz.navigation,
      loading: (state) => state.app.loading,
      currentQuiz: (state) => state.quiz.currentQuiz,
    }),

    sidebarShown() {
      return this.$store.state.app.sidebarShow;
    },
  },

  watch: {
    "$route.meta.quiz": {
      handler: function(isQuizPage) {
        if (!this.isQuizPage && isQuizPage) {
          this.hideSidebarDesktop();
        } else if (this.isQuizPage && !isQuizPage) {
          this.showSidebarDesktop();
        } else if (this.isQuizPage && isQuizPage) {
          this.hideSidebarDesktop();
        }

        this.isQuizPage = isQuizPage ? true : false;
      },
      deep: true,
      immediate: true,
    },
  },

  methods: {
    ...mapActions({
      toggleSidebarMobile: "app/toggleSidebarMobile",
      toggleSidebarDesktop: "app/toggleSidebarDesktop",
      showSidebarDesktop: "app/showSidebarDesktop",
      hideSidebarDesktop: "app/hideSidebarDesktop",
      quizNavigate: "quiz/navigate",
      startLoading: "app/startLoading",
      stopLoading: "app/stopLoading",
    }),
  },
};
</script>

<style lang="scss" scoped>
.iemq-header {
  border-bottom: 0;

  .return-link {
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    color: #000000;
    text-decoration: none;
    cursor: pointer;
  }

  .quiz-links {
    span {
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 20px;
      color: #9d9d9c;
      margin: 0 15px;
      cursor: pointer;
      transition: all 0.1s;
      border-bottom: 2px solid transparent;
      border-top: 2px solid transparent;

      &.active {
        color: #333333;
        border-bottom: 2px solid #333;
      }
    }
  }

  .quiz-button {
    min-width: auto;
  }

  .shadowed {
    box-shadow: rgba(33, 35, 38, 0.1) 0px 10px 10px -10px;
    display: inherit;
    z-index: inherit;
    width: 100%;
    min-height: 73px;
  }

  ::v-deep {
    .image-logo {
      width: 63px;
      height: 53px;
      padding: 10px 25px;
      box-sizing: content-box;
    }

    .shadowed {
      .c-header-toggler {
        span {
          filter: contrast(1);
          background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(0, 0, 21, 0.7)' stroke-width='2.25' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
        }
      }
    }
  }
}
</style>
